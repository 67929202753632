<template>
	<div>
		<NavHeader></NavHeader>
		<div class="login_page flex_column">
			<div class="content"  :class="language == 'en' ? 'en_style3': ''">
				<div class="mid flex_row">
					<div class="text"  :class="language == 'en' ? 'en_style1': ''">
						<span class="justify"  :class="language == 'en' ? 'en_style2': ''">{{$t('retrievePassword.account')}}</span>:</div>
					<el-input class="flex_1" ref="account" @clear="hiddenCode" @change="checkAccout" :placeholder="$t('retrievePassword.please')+$t('retrievePassword.account1')" v-model="formData.phone" size="mini" clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('retrievePassword.newpwd')}}</span>:</div>
					<el-input type="password" class="flex_1" show-password :placeholder="$t('retrievePassword.mesg5')" v-model="formData.password" size="mini" clearable></el-input>
				</div>
				<div class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('retrievePassword.enpwd')}}</span>:</div>
					<el-input type="password" class="flex_1" show-password :placeholder="$t('retrievePassword.mesg5')" v-model="formData.confirmPassword" size="mini" clearable></el-input>
				</div>
				<div v-if="code == 1" class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('retrievePassword.code')}}</span>:</div>
					<div class="flex_row flex_1">
						<el-input type="nummber" v-model="formData.smscode" size="mini" :placeholder="$t('retrievePassword.code')" clearable
							style="width: 100%;"></el-input>
						<el-button size="mini" type="warning" @click="get_sms_code" class="jinBtn"
							style="margin-left: 5px; width: 90%;border: none;">{{ vcodeBtnName }}</el-button>
					</div>
				</div>
				<div v-if="code == 2" class="mid flex_row">
					<div class="text" :class="language == 'en' ? 'en_style1': ''">
						<span class="justify" :class="language == 'en' ? 'en_style2': ''">{{$t('retrievePassword.code')}}</span>:</div>
					<div class="flex_row flex_1">
						<el-input type="nummber" v-model="formData.smscode" size="mini" :placeholder="$t('retrievePassword.code')" clearable
							style="width: 100%;"></el-input>
						<el-button size="mini" type="warning" @click="get_email_code" class="jinBtn"
							style="margin-left: 5px; width: 90%;border: none;">{{ vcodeBtnName }}</el-button>
					</div>
				</div>
				<div class="mid flex_row">
					<div class="text"></div>
					<div class="flex_1 cursor flex_row" style="color: #a4a498;justify-content: space-between; align-items: flex-start;">
						<div @click="$router.push('/login')">{{$t('retrievePassword.backlogin')}}</div>
						<div @click="$router.push('/registered')">{{$t('retrievePassword.reg')}}？</div>
					</div>
				</div>
				<div class="mid flex_row" style="height: 50px; justify-content: center">
					<el-button size="mini" class="jinBtn" style="border: none;" @click="doRegister" type="warning">{{$t('common.submit')}}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import NavHeader from '../components/NavHeader.vue';
import { passwordChange,sendSms,
		emailEvn } from '../api/api';
		import {
				mapState
			} from "vuex";
export default {
	name: 'retrievePassword',
	data() {
		return {
			vcodeBtnName: '发送验证码',
			countNum: 120,
			countDownTimer: null,
			code:0,
			agPassowrd:'',
			formData: {
				phone: '',
				smscode: '',
				confirmPassword: '',
				password: ''
			}
		};
	},
	components: {
		NavHeader
	},
	watch:{
		language(){
			this.vcodeBtnName=this.$t('retrievePassword.sendCode')
		}
	},
	computed: {
		...mapState(["language"])
		},
	methods: {
		hiddenCode(){
			this.code = 0;
		},
		checkEmail(){
			var rule = [
				{name:"phone", checkType : "email", checkRule:"", errorMsg:this.$t('common.formMsg.email')}
			];
			return this.$zuiChecker.check(this.formData, rule)
		},
		checkPhone(){
			var rule = [
				{name:"phone", checkType : "phoneno", checkRule:"", errorMsg:this.$t('common.formMsg.contactPhone')}
			];
			return this.$zuiChecker.check(this.formData, rule);
		},
		checkAccout(){
			// console.log(this.checkPhone(),this.checkEmail());
			if(this.checkPhone()){
					this.code = 1;
					return true;
			}
			if(this.checkEmail()){
					this.code = 2;
					return true;
			}
			//账号不属于 手机 或者 邮箱
			this.$message({
				message: this.$t('common.formMsg.account'),
				showClose: true,
				type: 'error',
				customClass:'hahaha',
				duration: 2000,
				offset: 20
			});
			this.$refs.account.focus();
		},
		get_sms_code: function() {
			
			if (!this.checkPhone()) {
				this.$message({
					message: this.$t('common.formMsg.contactPhone'),
					showClose: true,
					type: 'error',
					customClass:'hahaha',
					duration: 2000,
					offset: 20
				});
				return false;
			}
			// 手机号码为 :  this.phoneno
			// vcodeBtnName 可以阻止按钮被多次点击 多次发送 return 会终止函数继续运行
			if (this.vcodeBtnName != this.$t('retrievePassword.sendCode') && this.vcodeBtnName != this.$t('retrievePassword.sendCode1')) {
				return;
			}
			this.vcodeBtnName = this.$t('retrievePassword.sendCode2');
			// 与后端 api 交互，发送验证码 【自己写的具体业务代码】
			sendSms({
				mobile: this.formData.phone,
				smsmode: 2
			}).then(res => {
				// console.log(res);
				if (res.success) {
					// 假设发送成功，给用户提示
					this.$message({
						message: this.$t('retrievePassword.mesg'),
						showClose: true,
						type: 'success',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							// 倒计时
							this.countNum = 60;
							this.countDownTimer = setInterval(() => {
								this.countDown();
							}, 1000);
						}
					});
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					this.vcodeBtnName = this.$t('retrievePassword.sendCode')
					// console.log(res.message);
				}
			});
		},
		get_email_code(){
			// console.log('email');
			if (!this.checkEmail()) {
				this.$message({
					message: this.$t('retrievePassword.formMsg.email'),
					showClose: true,
					type: 'error',
					customClass:'hahaha',
					duration: 2000,
					offset: 20
				});
				return false;
			}
			// 手机号码为 :  this.phoneno
			// vcodeBtnName 可以阻止按钮被多次点击 多次发送 return 会终止函数继续运行
			if (this.vcodeBtnName != this.$t('retrievePassword.sendCode') && this.vcodeBtnName != this.$t('retrievePassword.sendCode1')) {
				return;
			}
			this.vcodeBtnName = this.$t('retrievePassword.sendCode2');
			// 与后端 api 交互，发送验证码 【自己写的具体业务代码】
			emailEvn['sendCode']({email:this.formData.phone}).then(res=>{
				if (res.success) {
					// 假设发送成功，给用户提示
					this.$message({
						essage: this.$t('retrievePassword.mesg1'),
						showClose: true,
						type: 'success',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							// 倒计时
							this.countNum = 60;
							this.countDownTimer = setInterval(() => {
								this.countDown();
							}, 1000);
						}
					});
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					this.vcodeBtnName = this.$t('retrievePassword.sendCode')
					// console.log(res.message);
				}
			})
		},
		countDown: function() {
			if (this.countNum < 1) {
				clearInterval(this.countDownTimer);
				this.vcodeBtnName = this.$t('retrievePassword.sendCode1');
				return;
			}
			this.countNum--;
			this.vcodeBtnName = this.countNum + this.$t('retrievePassword.mesg2');
		},
		doRegister() {
			// console.log(this.formData);
			if(this.formData.password !== this.formData.confirmPassword){
				this.$message({
					message: this.$t('retrievePassword.mesg3'),
					showClose: true,
					type: 'error',
					customClass:'hahaha',
					duration: 2000,
					offset: 20
				});
				return;
			}
			// this.formData.unUseToken = true;
			passwordChange(this.formData).then(res => {
				// console.log(res);
				if (res.success) {
					this.$message({
						message: this.$t('retrievePassword.mesg4'),
						showClose: true,
						type: 'success',
						customClass:'hahaha',
						offset: 20,
						duration: 2000,
						onClose: () => {
							this.$router.push({ name: 'login' });
						}
					});
				} else {
					this.$message({
						message: res.message,
						showClose: true,
						type: 'error',
						customClass:'hahaha',
						duration: 2000,
						offset: 20
					});
					// console.log(res.message);
				}
			});
		},
	},
	mounted() {
			this.vcodeBtnName=this.$t('retrievePassword.sendCode')
	}
};
</script>
<style lang="scss">
.login_page {
	width: 100%;
	height:100vh ;
	background: url('../assets/image/02.jpg') no-repeat center;
	background-size: cover;
		.en_style1{
			width: auto !important;
		}
		.en_style2{
			width: 180px !important;
			text-align-last: right !important;
			vertical-align: middle;
		}
		.en_style3{
			//width: 380px !important;
		}
	.content {
		margin-bottom: 100px;
		width: 400px;
		color: #000000;

		.mid {
			margin: 10px 0;
			justify-content: flex-start;
			.text {
				font-size: 14px;
				color: #000000;
				width: 90px;
				text-align: right;
				margin-right: 10px;
			}
			.justify{
				display: inline-block;
				width: 60px;
				text-align-last: justify;
			}
		}
	}
}
// 手机适配
	@media screen and (max-width: 960px) {
	.content{
		width: 98% !important;
	}
	.login_page {
	.en_style2{
			width: 100px !important;
		}
		}
	}
</style>
